


export const donationValues = {
    "fundraised_data": [
        {
            "date": "2024-01-25",
            "goalAmount": 12000,
            "fundRaised": 6000
        },
        {
            "date": "2024-01-26",
            "goalAmount": 1000,
            "fundRaised": 6000
        },
        {
            "date": "2024-01-27",
            "goalAmount": 2300,
            "fundRaised": 6000
        },
        {
            "date": "2024-01-28",
            "goalAmount": 1000,
            "fundRaised": 6000
        },
        {
            "date": "2024-01-29",
            "goalAmount": 1200,
            "fundRaised": 6000
        },
        {
            "date": "2024-01-30",
            "goalAmount": 12000,
            "fundRaised": 6000
        },
        {
            "date": "2024-01-31",
            "goalAmount": 0,
            "fundRaised": 6000
        },
        {
            "date": "2024-02-01",
            "goalAmount": 2300,
            "fundRaised": 6000
        },
        {
            "date": "2024-02-02",
            "goalAmount": 9000,
            "fundRaised": 6000
        },
        {
            "date": "2024-02-03",
            "goalAmount": 8000,
            "fundRaised": 6000
        },
        {
            "date": "2024-02-04",
            "goalAmount": 8000,
            "fundRaised": 6000
        },
        {
            "date": "2024-02-05",
            "goalAmount": 6000,
            "fundRaised": 6000
        },
        {
            "date": "2024-02-06",
            "goalAmount": 12000
        },
        {
            "date": "2024-02-07",
            "goalAmount": 0
        },
        {
            "date": "2024-02-08",
            "goalAmount": 12500
        },
        {
            "date": "2024-02-09",
            "goalAmount": 10000
        },
        {
            "date": "2024-02-10",
            "goalAmount": 0
        },
        {
            "date": "2024-02-11",
            "goalAmount": 120000
        },
        {
            "date": "2024-02-12",
            "goalAmount": 0
        },
        {
            "date": "2024-02-13",
            "goalAmount": 23000
        },
        {
            "date": "2024-02-14",
            "goalAmount": 12000
        },
        {
            "date": "2024-02-15",
            "goalAmount": 2300
        },
        {
            "date": "2024-02-16",
            "goalAmount": 34000
        },
        {
            "date": "2024-02-17",
            "goalAmount": 0
        },
        {
            "date": "2024-02-18",
            "goalAmount": 1200
        },
        {
            "date": "2024-02-19",
            "goalAmount": 1900
        },
        {
            "date": "2024-02-20",
            "goalAmount": 0
        },
        {
            "date": "2024-02-21",
            "goalAmount": 203011.0
        },
        {
            "date": "2024-02-22",
            "goalAmount": 0
        },
        {
            "date": "2024-02-23",
            "goalAmount": 12000
        }
    ]
}